<template>
  <div
    class="wet-section wet-section-vacation d-flex"
    :class="{
      'wet-full-height': !onlyForm,
      'py-0': onlyForm,
    }"
    :style="{
      backgroundImage: !onlyForm ? backgroundImage : '',
    }"
  >
    <div
      :class="{
        'position-relative': true,
        'w-100': onlyForm,
      }"
      style="display: flex; margin: auto;"
    >
      <div
        class="d-flex flex-column justify-content-center"
        style="height: auto"
      >
        <div v-if="!onlyForm" class="wet-section-title" style="text-align: center">
          <h1 class="empty-ph text-white">{{ str.vacation.section_title }}</h1>
        </div>
        <div
          class="wet-section-box-rounded pb-1 mt-2"
          style="margin: auto"
          :style="{
            'max-width': $route.query.iframe !== '2' ? '35rem' : null,
          }"
        >
          <div class="font-weight-bold text-white"
               style="text-align: center" v-html=offerText>
          </div>
          <div class="row align-items-end">
            <div class="col-12 col-xl mt-3 mb-0">
            </div>
          </div>
          <list-component
            :items="hotels"
            v-model="location"
            class="mb-0"
          />
          <div class="row align-items-end">
            <div class="col-12 col-xl mt-3">
              <period-alt
                :startDate.sync="formData.startDate"
                :endDate.sync="formData.endDate"
                :startText="str.vacation.start_date_label"
                :endText="str.vacation.end_date_label"
                :validationRules="validationRules"
                :calendLang="lang"
                :defaultStartDate="defaultStartDate"
                :defaultEndDate="defaultEndDate"
                :dates.sync="formData.dates"
                :disabled-start-date-message="firstDateInfo"
                calendar-rules="derTourPromotion"
                dark
                labeled
              />
            </div>
          </div>
          <div
            class="wet-input wet-input-with-label mt-4 mb-1"
            :class="$v.coupon_code.$error ? 'wet-input-error' : ''"
          >
            <input
              id="wet-input-coupon_code"
              type="text"
              :placeholder='str.vacation.coupon_code_placeholder'
              v-model.trim="$v.coupon_code.$model"
            >
            <label for="wet-input-firstname">
              {{ str.vacation.coupon_code_placeholder }}
            </label>
          </div>
          <div v-if="$v.coupon_code.$error" class="small text-danger py-1 mt-2 mb-3 mx-3">
            {{ str.vacation.error_coupon_code_format }}
          </div>
          <div class="row align-items-end mb-3">
            <div class="col-12 col-xl mt-3">
              <button class="wet-button wet-button-xs-full"
                      style="width: 100%"  @click="onNextClick" :disabled="nextDisabled">
                {{ str.vacation.btn_label }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import common from '@/mixins/common';
import hotelStyleLandingPage from '@/mixins/hotelStyleLandingPage';
import changeLang from '@/mixins/changeLang';
import periodAlt from '@/components/sections/periodAlt.vue';
import listComponent from '@/components/ListComponent.vue';
import places from '@/mixins/places';
import LogService from '@/services/LogService';
import wpSection from '@/enums/wpSection';
import { vacation as vacationStr, commonApp } from '@/enums/stringIndices';
import { nanoid } from 'nanoid';
import { coupon_code_der_tour } from '@/common/validations';

export default {
  mixins: [common, places, hotelStyleLandingPage, changeLang],
  components: {
    periodAlt,
    listComponent,
  },
  data() {
    return {
      coupon_code: '',
      businessType: 'vacation',
      partnerCode: 'wetterheld',
      campaignId: null,
      userStatus: '5',
      startDate: new Date(),
      endDate: new Date(),
      defaultStartDate: null,
      defaultEndDate: null,
      current: '',
      crops: [],
      formData: {},
      invalidCountry: false,
      searchTypes: [],
      screenWidth: 0,
      insuranceCountries: [],
      str: {
        vacation: vacationStr,
        commonApp,
      },
      countries: ['DE'],
    };
  },
  computed: {
    nextDisabled() {
      return !this?.location || this.location.id === '';
    },
    location: {
      set(val) {
        this.$set(this.formData, 'location', val);
      },
      get() {
        return this.formData.location;
      },
    },
    firstDateInfo() {
      const min_contract_start_postponement_less_29 = this.$store.state.settings.calendar?.vacation
        ?.min_contract_start_postponement_less_29;
      const postpone = min_contract_start_postponement_less_29
        ? (min_contract_start_postponement_less_29 + 1).toString()
        : '';
      const date = this.$moment.today().add(min_contract_start_postponement_less_29 + 1, 'days')
        .toDate().toLocaleDateString(this.lang);
      return this.format(
        this.str.vacation.first_date_info,
        date,
        postpone,
      );
    },
    wpSizes() {
      return this.str.vacation.background_image.sizes;
    },
    validationRules() {
      return this.settings.calendar?.vacation || {};
    },
    boundsEurope() {
      // europe
      const { maps } = this.googleApis;
      LogService.log('maps', maps);
      if (!maps) return undefined;
      const southWest = new maps.LatLng(32.880155, -14.177284);
      const northEast = new maps.LatLng(71.594625, 45.852008);
      return new maps.LatLngBounds(southWest, northEast);
    },
    errorTextFalseCountry() {
      // get country codes of allowed countries, map them to country names in correct languages and
      // join to a list
      LogService.log('Vacation errorTextFalseCountry, insuranceCountries', this.insuranceCountries);
      const regionNames = new Intl.DisplayNames([this.lang], { type: 'region' });
      LogService.log('Vacation errorTextFalseCountry, regionNames', regionNames);
      const insuranceCountriesList = this.insuranceCountries.map((x) => regionNames.of(x))
        .sort().join(', ');
      const selectedCountry = regionNames.of(this.formData.location.country_code);
      LogService.log('Vacation errorTextFalseCountry, popup text', this.str.vacation.error_wrong_country, selectedCountry,
        insuranceCountriesList);
      return this.format(this.str.vacation.error_wrong_country, selectedCountry,
        insuranceCountriesList);
    },
    errors() {
      const emptyLocation = Object.entries(this.formData.location || {})
        .filter(([key]) => key !== 'country')
        .every(([, val]) => !val);
      return this.invalidCountry && emptyLocation
        ? [this.str.vacation.error_location] : [];
    },
    onlyForm() {
      return this.$route.query.iframe === '2';
    },
    offerText() {
      return this.str.vacation.offer_text;
    },
    vacationVer2() {
      return process.env.VUE_APP_VACATION_SURVEY === 'true';
    },
    hotels() {
      return [
        {
          id: '',
          name: this.str.vacation.select_location,
        },
        {
          id: 'ChIJm269bGwRdEcRoqX7ZVc9EZM',
          address: 'Wörmansedt 1, 4843 Wörmansedt, Österreich',
          name: 'Aldiana Club Ampflwang',
          street: 'Wörmansedt',
          house: '1',
          zip: '4843',
          city: 'Wörmansedt',
          latitude: 48.0994043,
          longitude: 13.5413359,
          country_code: 'AT',
          country: 'Österreich',
        },
        {
          id: 'ChIJ_____7_edkcRrrk3hdEuESc',
          address: 'Mühlbach am Hochkönig 425, 5505 Mühlbach am Hochkönig, Österreich',
          name: 'Aldiana Club Hochkönig',
          street: 'Mühlbach am Hochkönig',
          house: '425',
          zip: '5505',
          city: 'Mühlbach am Hochkönig',
          latitude: 47.3808606,
          longitude: 13.1161139,
          country_code: 'AT',
          country: 'Österreich',
        },
        {
          id: 'ChIJ0bjMMfQ_cUcRqwWYB6qqT5E',
          address: 'Neuhofen 183, 8983 Bad Mitterndorf, Österreich',
          name: 'Aldiana Club Salzkammergut',
          street: 'Neuhofen',
          house: '183',
          zip: '8983',
          city: 'Bad Mitterndorf',
          latitude: 47.5454775,
          longitude: 13.9290913,
          country_code: 'AT',
          country: 'Österreich',
        },
      ];
    },
  },
  async created() {
    await this.init();
    await this.prefillFormData();
  },
  methods: {
    async init() {
      const loadingId = nanoid();
      await LogService.log('creating screen, loadingId', loadingId);
      await this.$store.commit('SET_LOADING', loadingId);
      await Promise.all([
        this.getWordPressStrings(
          [
            wpSection.VACATION,
            wpSection.COMMON_APP,
            wpSection.SECTIONS,
          ],
        ),
        this.$store.dispatch('settings/getProductSettings', { site: 'vacation' }).then(() => this.prefillFromBackendOnly()),
      ]);
      await this.$store.commit('DEL_LOADING', loadingId);
    },
    updateAddress(address) {
      LogService.log('Vacation updateAddress', address);
      this.formData.location = address;
    },
    async onNextClick() {
      this.$v.$touch();
      if (this.$v.coupon_code.$error) {
        return;
      }
      await this.postProductCouponAndFind(this.businessType);
      this.redirect();
    },
    redirect() {
      this.$store.commit('RESET_LOADING');
      LogService.log('redirecting to buy-vacation');
      this.$router.push({ query: null, name: 'buy-vacation', params: { ...this.$route.params } });
    },
  },
  validations() {
    return {
      coupon_code: coupon_code_der_tour,
    };
  },
  mounted() {
    this.loadMapScript()
      .then(() => this.initMaps());

    this.resize();
    this.$root.$on('resize', this.resize);
  },
  destroyed() {
    this.$root.$off('resize', this.resize);
  },
};
</script>

<style lang="scss">
.wet-section-vacation {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:
      radial-gradient(circle, rgba(0,0,0,.3) 0%, rgba(0,0,0,.5) 70%, rgba(0,0,0,.8) 100%);
  }

  &>.container {
    position: relative;
  }
}

.wet-section-box-rounded {
  border-radius: var(--border-radius);
  background-color: #3B384D;
  padding: 1.5rem;
}
</style>
