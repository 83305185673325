<template>
  <div class="form-group w-100">
    <label v-if="label" class="form-group-title empty-ph">{{label}}</label>
    <div class="custom-vegetation-select">
      <div
        id="drought-form-select"
        class="nice-select vegetation-period"
        :class="selectOpen ? 'open' : ''"
        @click="selectOpen = !selectOpen"
      >
        <span class="current">{{selected && selected.name}}</span>
        <ul class="list">
          <li
            class="option"
            :id="`wet-prod-option-${id + 1}`"
            :class="selected.id === item.id ? 'selected focus' : ''"
            v-for="(item, id) in items"
            :key="id"
            @click="selected = item"
          >{{item.name}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    label: String,
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectOpen: false,
    };
  },
  computed: {
    selected: {
      set(val) {
        this.$emit('input', val);
      },
      get() {
        const def = this.items[0] ? this.items[0] : {};
        return Object.keys(this.value || {}).length === 0 ? def : this.value;
      },
    },
  },
};
</script>
